"use client";

import { Box, Grid, Heading, Text, VStack } from "@biblioteksentralen/react";
import { ResolvedSiteSummary } from "@libry-content/common";
import { DefaultContainer } from "../../components/ContentContainer";
import { UnknownDomainHeader } from "../../components/errorPages/UnknownDomainHeader";
import { LinksToAllSites } from "../../components/LinksToAllSites";
import { SplashPageFooter } from "../../components/splashPage/SplashPageFooter";
import { useTranslation } from "../../utils/hooks/useTranslation";

type Props = {
  sites: ResolvedSiteSummary[];
};

export const NotFoundClientSide = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid gridTemplateRows="auto auto 1fr auto" minHeight="100vh">
        <UnknownDomainHeader />
        <DefaultContainer padding={{ base: "3rem 0 7rem", md: "5rem 0 9rem" }}>
          <VStack as="main" id="main" gap="1rem" alignItems="flex-start" padding={{ base: "0 1rem 0", sm: "0 3rem" }}>
            <Heading as="h1" fontSize="2xl" marginTop="0 !important">
              {t("Oi da! Siden finnes visst ikke")}
            </Heading>
            <Text maxWidth="18rem" fontSize="md">
              {t("Det ser ut til at du leter etter nettsiden til et bibliotek.")}
              {!!props.sites?.length && <> {t("Kan det være et av disse?")}</>}
            </Text>
            <LinksToAllSites sites={props.sites} marginTop="1rem !important" />
          </VStack>
        </DefaultContainer>
        <SplashPageFooter />
      </Grid>
    </Box>
  );
};
